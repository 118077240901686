.kr-embedded {
    @apply mt-6 mx-auto;

    .kr-payment-button {
        @extend .qo-big-button !optional;
        @apply bg-qo-primary dark:bg-qo-primary300 text-qo-primary-contrast dark:text-qo-primary300-contrast shadow-big-button shadow-qo-primary/30 dark:shadow-qo-primary300/20 h-auto mb-3 py-0.5 px-5 #{!important}; 

        > span {
            @apply font-display text-base md:text-lg font-medium;
        }

        .kr-spinner {
            .kr-icon {
                margin-top: 58px !important;
            }

            circle {
                @apply stroke-qo-primary-contrast/80 #{!important};
            }
        }
    }

    .kr-form-error {
        @apply mb-0;

        span {
            @apply font-display text-qo-warn;
        }
    }
}

.kr-embedded,
.kr-embedded.kr-help-button-inner-field .kr-pan, 
.kr-embedded.kr-help-button-inner-field .kr-field.kr-text, 
.kr-embedded.kr-help-button-inner-field .kr-field.kr-checkbox, 
.kr-embedded.kr-help-button-inner-field .kr-expiry, 
.kr-embedded.kr-help-button-inner-field .kr-security-code,
.kr-embedded.kr-help-button-inner-field .kr-payment-button, 
.kr-embedded.kr-help-button-inner-field .kr-form-error { 
    @apply box-border w-full max-w-340px;
}

.kr-embedded .kr-help-button-wrapper .kr-help-modal-wrapper .kr-help-modal .kr-help-modal-header,
.kr-embedded .kr-help-button-wrapper .kr-help-modal-wrapper .kr-help-modal .kr-help-modal-header .kr-help-modal-close-button svg {
    @apply bg-qo-primary #{!important}; 
}

.kr-embedded .kr-help-button-wrapper .kr-help-modal-wrapper .kr-help-modal .kr-help-modal-header .kr-help-modal-close-button svg path#path2 {
    @apply fill-qo-primary #{!important}; 
}

.kr-embedded .kr-label.focus label,
.kr-embedded .kr-label.kr-focus label {
    @apply text-qo-text;
}

.kr-embedded[krvue] .kr-field.kr-focus .kr-field-component, 
.kr-embedded[krvue] .kr-pan.kr-focus .kr-field-component, 
.kr-embedded[krvue] .kr-expiry.kr-focus .kr-field-component, 
.kr-embedded[krvue] .kr-security-code.kr-focus .kr-field-component, 
.kr-embedded[krvue] .kr-identity-document-number.kr-focus .kr-field-component, 
.kr-embedded[krvue] .kr-card-holder-name.kr-focus .kr-field-component, 
.kr-embedded[krvue] .kr-card-holder-mail.kr-focus .kr-field-component, 
.kr-embedded[krvue] .kr-identity-document-type.kr-focus .kr-field-component,
.kr-embedded[krvue] .kr-installment-number.kr-focus .kr-field-component {
    @apply border-b border-qo-primary;
}