// Material theming tools
@use 'sass:map';
@use '@angular/material' as mat;

@use "scss/themes";
@use "scss/application-form.theme";
@use "scss/print";

@use "scss/components/text.scss";
@use "scss/components/button.scss";
@use "scss/components/elements.scss";
@use "scss/components/layout.scss";
@use "scss/components/form.scss";
@use "scss/components/dialog-popover.scss";
@use "scss/components/tag-chip.scss";
@use "scss/components/list.scss";
@use "scss/components/export.scss";
@use "scss/components/misc.scss";
@use "scss/components/pages.scss";
@use "scss/components/payment.scss";
@use "scss/components/animation.scss";

// Tailwindcss
@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
    // for submit bar on little form
    --header-size: 52px;
    --footer-size: 56px; //footer or nav mobile

    @screen md {
        --footer-size: 42px;
        --header-size: 70px;
    }
}