.timeline-row {
    @apply block relative bg-clip-padding border-b-2 border-qo-light/30 transition-all duration-200;

    &.checked {
        @apply bg-qo-primary/30;
    }

    &.qo-selected {
        @apply bg-qo-primary/10 dark:bg-qo-primary300/10;
    }

    &:hover {
        @apply bg-qo-primary/20;
    }

    &:not(.without-shift) .selection-enabled.has-items {
        &:hover {
            @apply pb-[34px] #{!important};
        }
    }

    &.highlight {
        animation: 2s highlightLine ease-out;
    }
}

.mat-mdc-paginator {
    .mat-mdc-paginator-container {
        @apply p-0 min-h-10;
    }

    .mat-mdc-paginator-page-size-label,
    .mat-mdc-paginator-range-label {
        @apply text-sm;
    }

    .mat-mdc-paginator-range-label {
        @apply my-0 mx-2;
    }

    .mat-mdc-paginator-icon {
        @apply inline;
    }
}

.qo-Paginator {
    @apply bg-white dark:bg-qo-dark-700 dark:text-white float-right rounded-tl-md mt-4 pl-1 #{!important};

    .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
        @apply py-1.5 min-h-[36px];
    }

    .mat-mdc-paginator-page-size {
        @apply items-center;
    }

    .mat-mdc-paginator-page-size-label {
        @apply hidden md:block;
    }
}

.mat-mdc-table + .qo-Paginator {
    @apply sticky bottom-0;
}

.qo-TableList-checkbox {
    @apply overflow-visible #{!important};
}

.mat-mdc-table {
    @apply bg-transparent #{!important};
}

.qo-TableList {
    .mat-mdc-table-sticky {
        @apply z-1 #{!important};
    }

    .mat-mdc-header-row {
        @apply sticky -top-px z-2 border-none py-1 mb-px min-h-10 rounded-b-lg bg-white dark:bg-qo-dark-700 before:content-[''] before:inset-0 before:bg-qo-neutral-old before:dark:bg-qo-dark-600 before:absolute before:rounded-lg #{!important};
    }

    .mat-mdc-header-cell {
        @apply bg-transparent text-qo-primary/80 dark:text-qo-primary300/80 text-base font-medium border-none #{!important};

        .mat-sort-header-arrow {
            @extend .qo-PrimaryText !optional;
        }

        &:not(.mat-column-checkbox) {
            @apply relative;
        }
    }

    .mat-sort-header-content {
        @apply text-left;
    }

    .mat-sort-header-sorted {
        @extend .qo-PrimaryText !optional;
        @apply font-bold uppercase;
    }

    .mat-mdc-cell {
        @apply dark:text-white border-none;
    }

    .mat-mdc-row {
        @apply min-h-10 relative border-solid border-t-0 border-x-0 border-b-2 border-qo-neutral-old dark:border-qo-light/20 transition-all duration-200 #{!important};

        &.checked {
            &:before {
                @apply bg-qo-primary/30 dark:bg-qo-primary300/30;
            }
        }

        &.warn {
            &:before {
                @apply bg-qo-warn/15;
            }
            &:hover {
                &:before {
                    @apply bg-qo-warn/20;
                }
            }
            &.checked {
                &:before {
                    @apply bg-qo-warn/25;
                }
            }
        }

        &.qo-selected {
            &:before {
                @apply bg-qo-primary/10 dark:bg-qo-primary300/10;
            }
        }

        &:before {
            @apply absolute inset-0 content-[""] rounded-lg transition-all duration-200
        }

        &:hover {
            @apply cursor-pointer;

            &:before {
                @apply bg-qo-primary/20 dark:bg-qo-primary300/20;
            }
        }
    }

    &.qo-Report {
        @apply min-w-fit #{!important};

        .mat-mdc-header-row {
            @apply p-0 pl-2 #{!important};
        }

        .mat-mdc-row {
            @apply min-h-0 #{!important};
        }

        .mat-mdc-cell {
            @apply py-1 #{!important};
        }
    }

    &.qo-no-action {
        .mat-mdc-header-row,
        .mat-mdc-row {
            @apply pl-2;

            &:hover {
                @apply cursor-default;
            }
        }
    }

    /*.qo-no-action {
        &.mat-mdc-row {
            &:hover {
                @apply cursor-default;
            }
        }

        .mat-mdc-cell:not(.mat-column-checkbox) {
            @apply pointer-events-none #{!important};
        }

        .mat-column-buttons button {
            @apply hidden #{!important};
        }
    }*/

    .mat-mdc-cell {
        @apply relative py-2 ;

        p {
            @apply mb-0;
        }
    }

    .mat-mdc-cell,
    .mat-mdc-header-cell,
    .mat-mdc-footer-cell {
        @apply pl-0 pr-0.5 first-of-type:pl-0.5 last-of-type:pr-1;
    }

    .mat-column-checkbox {
        @apply box-content grow-0 shrink basis-10;
    }

    .mat-column-name {
        @apply font-medium;

        avatar {
            @apply mr-2 text-46px;
        }
    }

    .mat-column-buttons {
        @apply grow-0 shrink basis-12 md:basis-28 justify-end pr-0;

        button {
            @apply md:opacity-0 md:transition-all md:duration-200;
        }
    }

    .mat-mdc-row:hover .mat-column-buttons button,
    .mat-row:focus .mat-column-buttons button,
    .mat-column-buttons button[aria-expanded=true] {
        @apply md:opacity-100;
    }

    .qo-TableList-settings {
        @apply text-qo-primary/60;
    }
}

.mat-mdc-dialog-container {
    .qo-TableList {
        .mat-mdc-table-sticky {
            @apply -top-2 #{!important};
        }
    }
}

.qo-with-header-mobile .mat-mdc-table-sticky {
    @apply top-14 md:top-0 #{!important};
}

.qo-edit {
    .qo-TimelineRow-link {
        @apply cursor-pointer;
    }
}

.qo-HoverBtn[aria-expanded=true] {
    @apply opacity-100 #{!important};
}
